import $ from "jquery"
import _ from "lodash"
import "bootstrap/js/dist/util"
import "bootstrap/js/dist/dropdown"
import "./style/app.scss"
import md from "lib/md"

const postProcessedTags = ["img", "h1", "h3", "h4", "ul"];

function renderMdElement($e) {
  let text = {
    type: $e.hasClass("inline-md") ? "inline-md" : "md",
    value: $e.html()
  };
  let m = md.renderMarkdown(text);

  $e.html(m);
  postProcessedTags.forEach(tagName => {
    // allow adding css class(es) to some tags in md output
    let cls = $e.data("md-" + tagName + "-class");
    if(cls) {
      $e
        .removeAttr("data-md-" + tagName + "-class") // clean up attr clutter
        .find(tagName).addClass(cls);
    }
  });

  // hide links to unresolved variables
  $e.find("a[href^='$%7B']").addClass("d-none");

  // replace links to youtube video embeds with a player
  $e.find("a[href^='https://www.youtube.com/embed/']").replaceWith(function() {
    let $a = $(this);
    let $div = $("<div/>", {
      "class": "video-wrapper"
    });
    let $iframe = $("<iframe/>", {
      src: $a.attr("href"),
      title: $a.text(),
      frameborder: "0",
      allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      allowfullscreen: ""
    });

    $div.append($iframe);
    return $div;
  });
}

$(() => {
  $(".md.src, .inline-md.src").each(function() {
    renderMdElement($(this));
  });

  // vista2020 hacks
  $(".nocms-page .jumbotron.intro").each(function() {
    let $j = $(this);
    let $page = $j.closest(".nocms-page");
    // replace h1 and h4 with h2 in top jumbotrons
    $j.find("h1, h3, h4").replaceWith(function() {
      return $("<h2>").append($(this).contents());
    });
    // tag h2:s containing "VISTA2020"
    $j.find("h2 > strong").each(function() {
      let $e = $(this);
      if($e.text() === "VISTA") {
        $e.parent().addClass("vista-logotype");
      }
    });
    // on index page, move logotype h2 one level up
    if($page.hasClass("index")) {
      let $e = $j.find("h2.vista-logotype");
      let $ep = $e.parent();
      $e.detach().insertBefore($ep);
    }
  });


  // show elements
  $(".md.src, .inline-md.src").removeClass("src");
});
